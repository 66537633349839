// ** Auth Endpoints
const base_url =  `${process.env.REACT_APP_API_URL}/api` 
const ENDPOINTS =  {
  loginEndpoint: base_url + '/auth/login',
  verify : base_url + '/auth/verify-otp',
  getSettings : base_url + '/settings/user/',
  globalSettings : base_url + '/global-settings/user/',
  registerEndpoint: base_url + '/auth/refresh-token/',
  refreshEndpoint: base_url + '/auth/refresh-token',
  logoutEndpoint: base_url + '/auth/logout',
  trade: base_url + '/trade', 

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken' ,
  kiteAccessToken : 'kite_access_token' 
}


export default ENDPOINTS