// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import useJwt from "@src/auth/jwt/useJwt";
import toast from "react-hot-toast";

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async (id) => {
    try {
      const response = await useJwt.getSettings(id);
      return response.data;
    } catch (err) {
      const error = err?.response?.data?.message || "Something went wrong , can not get settings.";
      toast.error(error)
      return error;
    }
  }
);

export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async (data) => {
    try {
      const res = await useJwt.updateSettings(data); 
      toast.success(res?.data?.Message || "");
      return {
        type: data.type,
        value: data.value,
        stocklist_id: data.stocklist_id,
      };
    } catch (err) {
      console.log(err);
      const error = err?.response?.data?.message || "Something went wrong";
      toast.error(error);
      return error;
    }
  }
);

export const fetchGlobalSettings = createAsyncThunk(
  "settings/fetchGlobalSettings",
  async (id) => {
    try {
      const response = await useJwt.getGlobalSettings(id); 
      return response.data;
    } catch (err) {
      const error = err?.response?.data?.message || "Something went wrong , can not fetch global settings.";
      toast.error(error) 
      return error;
    } 
  }
);

export const updateGlobalSettings = createAsyncThunk('settings/updateGlobalSettings' , async (data) => {
  try {
    const res = await useJwt.udpateGlobalSettings(data); 
    toast.success(res?.data?.Message || "");
    return {
      vix_threshold: data.vix_threshold,
      spread: data.spread,
      profit_threshold: data.profit_threshold,
      max_price_threshold:data.max_price_threshold
    };
  }
  catch(err){
    console.log(err);
    const error = err?.response?.data?.message || "Something went wrong";
    toast.error(error);
    return error;
  }
})



export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settingsTable: {
      data: [],
      loading: false,
    },
    globalSettings: {
      data: [],
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state, action) => {
        state.settingsTable.data = [];
        state.settingsTable.loading = true;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.settingsTable.data = action?.payload?.data || [];
        state.settingsTable.loading = false;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.settingsTable.data = [];
        state.settingsTable.loading = false;
      })
      .addCase(fetchGlobalSettings.pending, (state, action) => {
        state.globalSettings.loading = true;
      })
      .addCase(fetchGlobalSettings.fulfilled, (state, action) => {
        state.globalSettings.loading = false;
        state.globalSettings.data = action.payload?.data || [];
      })
      .addCase(fetchGlobalSettings.rejected, (state, action) => {
        state.globalSettings.loading = false;
        state.globalSettings.false = [];
      })
      .addCase(updateGlobalSettings.fulfilled, (state, action) => {
        const globalSetting = state.globalSettings?.data[0] 
        state.globalSettings.data[0] = {
          ...globalSetting,
          ...action.payload
        } 
      })
      .addCase(updateSettings.fulfilled, (state, action) => { 
        const settingIdx = state.settingsTable.data.findIndex(
          (s) => s.stocklist.id == action.payload.stocklist_id
        );
        let setting = state.settingsTable.data[settingIdx];
        state.settingsTable.data[settingIdx] = {
          ...setting,
          [action.payload.type]: action.payload.value,
        };
      });
  },
});

export const { selectEvent } = settingsSlice.actions;

export default settingsSlice.reducer;
