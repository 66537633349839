export const SyncWithZerodhaURL = `https://kite.zerodha.com/connect/login?v=3&api_key=${process.env.REACT_APP_KITE_API_KEY}&redirect_url=${process.env.REACT_APP_API_URL}/api/zerodha/callback`;
export const connectBasketUrl = "https://kite.zerodha.com/connect/basket"

export const maxDeals = 50;
export const optionChainVisibility  = {
    hide : 0,
    ce_pink : 1,
    pe_pink : 2,
    row_green : 3,
    normal : 4
}

export const SettingFormProps = [
    {
      name: "vix_threshold",
      label: "VIX Threshold",
      type: "number",
      id: "vix_threshold",
      placeholder: "Enter value",
    },
    {
      name: "spread",
      label: "Spread (Minimum) %",
      type: "number",
      id: "spread",
      placeholder: "Enter Percentage",
    },
    {
      name: "profit_threshold",
      label: "Profit Threshold",
      type: "number",
      id: "profit_threshold",
      placeholder: "Enter value",
    },
    {
      name: "max_price_threshold",
      label: "Max Price Threshold %",
      type: "number",
      id: "max_price_threshold",
      placeholder: "Enter percentage",
    },
  ];