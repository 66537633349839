// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useJwt from "@src/auth/jwt/useJwt"; 
import moment from "moment/moment";
import toast from "react-hot-toast";
import {getNPercentOFTrade} from "@src/utility/Utils.js"
import {optionChainVisibility} from "@src/utility/constant.js"

// it will get the row of ( pe or ce  ) which have same strike
const getExchangeRow = (currentIdx, data, strike) => {
  for (let i = currentIdx; i < data.length; i++) {
    if (data[i].strike == strike) return [i, data[i]];
  }
};

// it will convert an array of params into a string
function getParamsString(paramsList) {
  let paramString = "";
  for (let param of paramsList) {
    paramString += `i=${param}&`;
  }
  return paramString;
}
const chunkSize = 200;

export const getParam = (obj) => {
  return `${obj.exchange}:${obj.tradingsymbol}` 
};

// this function will convert the trade's exhange:tradingSymbol into params ,
// it will return a list of params , which we'll use to call ltp apis
// list format = [ paramString1 , paramString2 , ... ] i.e every paramString is a chunk of params which we use to call api
const getParams = (newTrades, currentTrade) => { 
  let params = [[]];
  for (let i = 0; i < newTrades.length; i++) {
    const trade = newTrades[i];
    const ceObj = trade.ce;
    const peObj = trade.pe;

    let i1 = getParam(ceObj);
    let i2 = getParam(peObj);
 
    const param = i1 != "" && i2 != "" ? `${i1}&i=${i2}` : "";
    if (params[params.length - 1].length < chunkSize) {
      params[params.length - 1].push(param);
    } else {
      params.push([param]);
    }
  }

  const paramsStringArray = [];
  for (let paramsRow of params) {
    const str = getParamsString(paramsRow);
    paramsStringArray.push(str);
  }
 
  let currentTradeParam =
    currentTrade?.exchange && currentTrade?.tradingsymbol
      ? `&i=${currentTrade.exchange}:${currentTrade.tradingsymbol}`
      : "";
  paramsStringArray[paramsStringArray.length - 1] =
    paramsStringArray[paramsStringArray.length - 1] + currentTradeParam;
  return paramsStringArray;
};

// this function will provide the combined row data with ( ce param , pe param and stike.
// here param is the combination of exchange and tradingsymbol (exhange:tradingSymbol)
// which we will use furthur for ltp api call  )
const getTradeRow = (tradeList, i, strike) => {
  let pe = null;
  let ce = null;
  if (tradeList[i]?.instrument_type == "CE") {
    ce = tradeList[i];
    let [index, row] = getExchangeRow(i + 1, tradeList, strike);
    pe = row;
    return { pe, ce, index };
  } else {
    pe = tradeList[i];
    let [index, row] = getExchangeRow(i + 1, tradeList, strike);
    ce = row;
    return { pe, ce, index };
  }
};

// collect last_price row from ltp data )
const getLTP = (param,lastPrices) => { 
  if (lastPrices && param in lastPrices) {
    return lastPrices[param];
  }
  return null;
};


// this function will handle visibility of columns and row, either they should be colored ( pink , green ) , normal or  hidden.
const handleVisibility = (currentTrade, strike, getState, lastPricesList, greenRowCount) => {
  const state = getState()
  const globalSettings = state.settings.globalSettings;
  const currentTradeLastPrice = getCurrentTradeLastPrice(currentTrade, lastPricesList);
  const midPlus =
    currentTradeLastPrice +
    getNPercentOFTrade(
      currentTradeLastPrice,
      globalSettings?.data[0]?.spread || 0
    );
  const midMinus =
    currentTradeLastPrice -
    getNPercentOFTrade(
      currentTradeLastPrice,
      globalSettings?.data[0]?.spread || 0
    );
 
  let visibility = optionChainVisibility.normal;

  if (strike <= midPlus && strike >= midMinus) {
    if (greenRowCount.count < 1) {
      visibility = optionChainVisibility.row_green;
      greenRowCount.count = greenRowCount.count + 1;
    } else visibility = optionChainVisibility.hide;
  } else if (strike > midPlus) {
    if (greenRowCount.count < 2) {
      visibility = optionChainVisibility.row_green;
      greenRowCount.count = greenRowCount.count + 1;
    } else {
      visibility = optionChainVisibility.ce_pink;
    }
  } else if (strike < midMinus) {
    visibility = optionChainVisibility.pe_pink;
  }

  return visibility;
};

const getCurrentTradeLastPrice = (currentTrade, lastPricesList) => {
  const { tradingsymbol, exchange } = currentTrade;
  const param = `${exchange}:${tradingsymbol}`;
  const currentTradeLastPrice = getLTP(param, lastPricesList);
  return currentTradeLastPrice?.last_price || 0;
};


// this function will combine two trades which have same  strike
const combineTradesWithCE_and_PE = (tradeList) => {
  const newTradesList = [];
  for (let i = 0; i < tradeList.length; i++) {
    if (!tradeList[i]?.checked) {
      let strike = tradeList[i].strike;

      const { pe, ce, index } = getTradeRow(tradeList, i, strike);
      const newTradeRow = {
        strike,
        ce,
        pe,
      };

      newTradesList.push(newTradeRow);

      tradeList[i] = { ...tradeList[i], checked: true };
      tradeList[index] = { ...tradeList[index], checked: true };
    }
  } 
  return newTradesList;
};

const getLastPricesFun = async (combinedTradeList, currentTrade) => { 
    const params = getParams(combinedTradeList, currentTrade);
 
    const promises = params.map((paramList) => {
      return useJwt.getLastPrices(paramList);
    });

    const resArray = await Promise.all(promises);

    let last_prices_list = {};
    for (let lastPricesChunk of resArray) {
      const pricesData = lastPricesChunk?.data?.data || [];
      last_prices_list = { ...last_prices_list, ...pricesData };
    }
    return last_prices_list;
   
};

const getOptionChainList = (combinedTradeList, currentTrade, lastPricesList, getState) => {
  if (combinedTradeList.length) {
    let optionChainDataList = [];
    var greenRowCount = {
      count : 0
    } 
    for (let tIdx in combinedTradeList) {
      let t = combinedTradeList[tIdx];
      const pe = t.pe;
      const ce = t.ce;
      const PE_Param = getParam(pe);
      const CE_Param = getParam(ce);

      const PE_LTP = getLTP(PE_Param, lastPricesList);
      const CE_LTP = getLTP(CE_Param, lastPricesList );

      const strike = t?.strike || 0;
 
      let visibility = handleVisibility(currentTrade, strike, getState, lastPricesList, greenRowCount);
  
      let optionChain = {
        strike: t?.strike || 0,
        pe: {
          ltp: PE_LTP?.last_price || 0,
          ...pe,
        },
        ce: {
          ltp: CE_LTP?.last_price || 0,
          ...ce,
        },
        visibility: visibility,
      };
      optionChainDataList.push(optionChain);
    }
    return optionChainDataList
  }
  return []
}

export const fetchTrades = createAsyncThunk("trade/fetchTrades", async (e, { getState }) => {
  try {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const res = await useJwt.getTrades(today);
    let tradeList = res?.data?.data?.trade_list || [];
    let currentTrade = res?.data?.data?.trade || {}; 
    const combinedTradeList = combineTradesWithCE_and_PE(tradeList);
    const lastPricesList = await getLastPricesFun(combinedTradeList, currentTrade) 
    const optionChainDataList = getOptionChainList(combinedTradeList, currentTrade, lastPricesList, getState)

    return { optionChainDataList: optionChainDataList, trade: currentTrade };
  } catch (err) {
    console.log(err);
    const error =
      err?.response?.data?.message ||
      "Something went wrong";
    toast.error(error);
    return error;
  }
});

export const tradeSlice = createSlice({
  name: "tradeData",
  initialState: {
    lastPrices: {},
    loading: false,
    trades: [],
    trade: {},
    optionChainData: [],
    deals : [],
    refetch : true
  },
  reducers: {
    clearLastPricesData: (state) => {
      state.lastPrices = {};
    },
    clearTrades: (state) => {
      state.trades = [];
    },
    addDeals : (state , action) => {
      state.deals = action.payload,
      state.refetch = false
    },
    handleRefetch : (state , action ) => {
      state.refetch = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrades.pending, (state, action) => {
        state.optionChainData = [];
        state.trade = {};
        state.loading = true;
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        state.optionChainData = action.payload.optionChainDataList;
        state.trade = action.payload.trade;
        state.loading = false;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.optionChainData = [];
        state.trade = {};
        state.loading = false;
      });
  },
});

export const { clearLastPricesData, clearTrades, addDeals,handleRefetch } =
  tradeSlice.actions;

export default tradeSlice.reducer;
