// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload 
      localStorage.setItem('userData', JSON.stringify(action.payload)) 
      localStorage.setItem('isAuthenticatedUser', JSON.stringify(action.payload.isAuthenticatedUser))  
    },
    verify : (state , action ) => { 
      state.userData.isAuthenticatedUser = action.payload?.data?.isAuthenticatedUser;
      state[config.storageTokenKeyName] = action.payload?.tokens?.access?.token || ''
      state[config.storageRefreshTokenKeyName] = action.payload?.tokens?.refresh?.token || ''

      localStorage.setItem('isAuthenticatedUser', action.payload?.data?.isAuthenticatedUser) 
      localStorage.setItem(config.storageTokenKeyName, action.payload?.tokens?.access?.token)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload?.tokens?.refresh?.token)
    },
    handleLogout: state => { 
      state.userData = {} 
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.removeItem('userData') 
      localStorage.removeItem('isAuthenticatedUser') 
      localStorage.removeItem(config.storageTokenKeyName) 
      localStorage.removeItem(config.storageRefreshTokenKeyName) 
    }
  }
})

export const { handleLogin, handleLogout , verify } = authSlice.actions

export default authSlice.reducer
