import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  axiosInstance = axios.create()
  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    this.axiosInstance.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const kiteAccessToken = this.getKiteAccessToken();
 
        // ** If token is present add it to request's Authorization Header
        config.headers["ngrok-skip-browser-warning"] = "69420"

        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        if (kiteAccessToken) {
          config.headers['kitetoken'] = kiteAccessToken;
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    this.axiosInstance.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
 
        if (error.response && error.response.message === "TokenException") {
          // Modify the error message
          error.message =
            "Please re-sync with zerodha, your access token is expired";
          localStorage.removeItem(this.kiteConfig.kiteAccessToken);
        }

        // ** if (status === 401) {
        if (response && (response.status === 401 )) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage 
              this.setToken(r.data.access.token)
              this.setRefreshToken(r.data.refresh.token)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => { 
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosInstance(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getKiteAccessToken() {
    return localStorage.getItem(this.jwtConfig.kiteAccessToken);
  }

  getLastPrices(params) { 
    return this.axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/zerodha/ltp`  + "?" + params );
  }
 
  getMarginBasket(payload){
    return this.axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/zerodha/margin-basket` , payload )
  }
  
  getRefreshToken() { 
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosInstance.post(this.jwtConfig.loginEndpoint, ...args)
  }

  verify(...args){
    return this.axiosInstance.post(this.jwtConfig.verify , ...args)
  }
  getSettings(id){ 
    return this.axiosInstance.get(this.jwtConfig.getSettings + id )
  }
  
  getTrades(date){
    return this.axiosInstance.get(this.jwtConfig.trade+`?date=${date}`)
  } 

  register(...args) {
    return this.axiosInstance.post(this.jwtConfig.registerEndpoint, ...args)
  }

  logout(){
    let refreshToken = this.getRefreshToken()
    return this.axiosInstance.post(this.jwtConfig.logoutEndpoint ,{
      refreshToken
    })
  }

  updateSettings({ id , stocklist_id,  type , value}){ 
    return this.axiosInstance.put(this.jwtConfig.getSettings + id , { stocklist_id , [type] : value })
  }

  getGlobalSettings(id){ 
    return this.axiosInstance.get(this.jwtConfig.globalSettings + id)
  }

  udpateGlobalSettings({id , ...data}){ 
    return this.axiosInstance.put(this.jwtConfig.globalSettings + id , data)
  }

  refreshToken() {
    let refreshToken = this.getRefreshToken()
    return this.axiosInstance.post(this.jwtConfig.refreshEndpoint, {
      refreshToken
    })
  }

}
